import React from 'react'
import Layout from '../components/Layouts/layout.js'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '../components/container'
import Wrapper from '../components/wrapper/wrapper'
import { GlobalStyle } from '../utils/global'

const propTypes = {
  data: PropTypes.object.isRequired,
}
const fourofourPage = {
  marginTop: '300px',
}
class HomeTemplate extends React.Component {
  render() {
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Container>
          <Wrapper>
            <div style={fourofourPage}>
              <a href="https://www.crea.ca/">
                <img
                  style={{ paddingBottom: '20px' }}
                  src={require('../images/logo_crea_EN.svg')}
                />
              </a>
              <h1>...Opps, Page Not Found</h1>
              <p>You just hit a route that doesn&#39;t exist... </p>
              <p>You can return back to</p>
              <h3>
                <a href="https://www.crea.ca/">CREA.ca</a>
              </h3>
              <h3>
                <a href="https://creastats.crea.ca/">CREAStats.ca</a>
              </h3>
            </div>
          </Wrapper>
        </Container>
      </Layout>
    )
  }
}

HomeTemplate.propTypes = propTypes

export default HomeTemplate

export const pageQuery = graphql`
  query BingoQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allContentfulBoards {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
